<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Funcionarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Funcionarios</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_funcionario_export"
                        v-if="$store.getters.can('admin.funcionarios.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('admin.funcionarios.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Tipo Documento
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_documento"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_documento in listasForms.tipo_documentos"
                            :key="tipo_documento.numeracion"
                            :value="tipo_documento.numeracion"
                          >
                            {{ tipo_documento.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Numero Documento
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.numero_documento"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Email
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.email"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Área
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.area"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="area in listasForms.areas"
                            :key="area.numeracion"
                            :value="area.numeracion"
                          >
                            {{ area.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Cargo
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.cargo"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="cargo in listasForms.cargos"
                            :key="cargo.numeracion"
                            :value="cargo.numeracion"
                          >
                            {{ cargo.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in funcionarios.data" :key="item.id">
                      <td>
                        <a
                          :href="uri_docs + item.link_fotografia"
                          target="_blank"
                        >
                          <img
                            :src="uri_docs + item.link_fotografia"
                            alt="Logo"
                            width="75"
                            class="rounded mx-auto d-block"
                          />
                        </a>
                      </td>
                      <td>{{ item.tipo_doc }}</td>
                      <td>{{ item.numero_documento }}</td>
                      <td>{{ item.nombres + " " + item.apellidos }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.area_f }}</td>
                      <td>{{ item.cargo_f }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          data-toggle="modal"
                          data-target="#modal-form-estado"
                          style="cursor: pointer"
                          v-if="
                            $store.getters.can('admin.funcionarios.editEstado')
                          "
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                          @click="llenarModal(item)"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                        <span
                          class="badge"
                          v-else
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-info"
                            v-if="$store.getters.can('admin.funcionarios.show')"
                            @click="edit(item, 1)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('admin.funcionarios.edit')"
                            @click="edit(item, 2)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can('admin.funcionarios.delete')
                            "
                            @click="destroy(item.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="funcionarios.total">
                  <p>
                    Mostrando del <b>{{ funcionarios.from }}</b> al
                    <b>{{ funcionarios.to }}</b> de un total:
                    <b>{{ funcionarios.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="funcionarios"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <FuncionarioEstado ref="FuncionarioEstado" />
        <FuncionarioExport ref="FuncionarioExport" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import FuncionarioEstado from "./FuncionarioEstado";
import FuncionarioExport from "./FuncionarioExport";

export default {
  name: "Funcionarios",
  components: {
    Loading,
    pagination,
    FuncionarioEstado,
    FuncionarioExport,
  },
  data() {
    return {
      cargando: true,
      tipo_usuario_cambia_estado: null,
      filtros: {
        tipo_documento: null,
        numero_documento: null,
        estado: null,
        email: null,
        cargo: null,
        area: null,
      },
      funcionarios: {},
      listasForms: {
        tipo_documentos: [],
        estados: [],
        areas: [],
        cargos: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/funcionarios?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.funcionarios = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTiposIdentificacion() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_documentos = response.data;
      });
    },

    getAreas() {
      axios.get("/api/lista/78").then((response) => {
        this.listasForms.areas = response.data;
      });
    },
    getCargos() {
      axios.get("/api/lista/77").then((response) => {
        this.listasForms.cargos = response.data;
      });
    },

    edit(funcionario, opcion) {
      return this.$router.push({
        name: "/Admin/FuncionarioForm",
        params: {
          accion: "Editar",
          data_edit: funcionario,
          id: funcionario.id,
          opcion: opcion,
        },
      });
    },

    destroy(idFuncionario) {
      this.$swal({
        title: "Esta seguro de eliminar este Funcionario?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, ElimInar!",
      }).then((result) => {
        // <--
        if (result.value) {
          this.cargando = true;
          axios.delete("/api/admin/funcionarios/" + idFuncionario).then(() => {
            this.cargando = false;
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el funcionario exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    llenarModal(funcionario) {
      if (this.$store.getters.can("admin.funcionarios.editEstado")) {
        this.$refs.FuncionarioEstado.form_estado = {};
        this.$refs.FuncionarioEstado.llenar_modal(funcionario);
      }
    },

    create() {
      return this.$router.push({
        name: "/Admin/FuncionarioForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTiposIdentificacion();
    this.getMsg();
    this.getAreas();
    this.getCargos();
  },
};
</script>
